<template>
  <div>
    <h3 class="mb-10 text-body-lg font-weight-medium">Password</h3>
    <ValidationObserver v-slot="{ handleSubmit }" ref="form" tag="div">
      <v-form @submit.prevent="handleSubmit(submitHandler)" style="max-width: 450px;">
        <ValidationProvider v-if="!getUser.social"
                            class="mb-3 mb-sm-8" mode="eager" tag="div"
                            name="Your old password" vid="old_password" rules="required|min:8|max:255" v-slot="{ errors }">
          <div class="mb-2 text-body font-weight-light gray-100--text">Old password</div>
          <v-text-field
            v-model="passwordData.old_password"
            type="password"
            dense
            outlined
            hide-details
            @keydown.space.prevent
            :disabled="loading"
            :error="!!errors.length"
            placeholder="Enter your old password"
          />
          <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
        </ValidationProvider>

        <ValidationProvider class="mb-3 mb-sm-8" mode="eager" tag="div"
                            name="your new password" vid="password" rules="required|min:8|max:100|unique_chars:5|password_spec_char"  v-slot="{ errors }">
          <div class="mb-2 text-body font-weight-light gray-100--text">New password</div>
          <v-text-field
            v-model="passwordData.password"
            type="password"
            dense
            outlined
            hide-details
            @keydown.space.prevent
            :disabled="loading"
            :error="!!errors.length"
            placeholder="Enter your new password"
          />
          <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
        </ValidationProvider>

        <ValidationProvider class="mb-8 mb-sm-13" mode="eager" tag="div"
                            name="your new password" vid="password_confirmation" rules="required|min:8|max:100|unique_chars:5|password_spec_char|confirmed:password" v-slot="{ errors }">
          <div class="mb-2 text-body font-weight-light gray-100--text">Confirm new password</div>
          <v-text-field
            v-model="passwordData.password_confirmation"
            type="password"
            dense
            outlined
            hide-details
            @keydown.space.prevent
            :disabled="loading"
            :error="!!errors.length"
            placeholder="Please confirm your new password"
          />
          <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
        </ValidationProvider>

        <UiBtn
          type="submit"
          color="accent"
          width="216"
          :loading="loading"
        >
          Update Password
        </UiBtn>

      </v-form>
    </ValidationObserver>

    <v-dialog
      v-model="successModal"
      max-width="420"
      style="z-index: 999!important;"
    >
      <div class="relative gray-0 pt-12 pb-8 px-6 text-center">
        <h6 class="mb-9 text-title-2 gray-100--text">
          <IconCheckCircle width="24" class="mr-1 success--text" style="vertical-align: top"/>
          Password Updated Successfully
        </h6>
        <UiBtn
          color="accent"
          width="216"
          :loading="loading"
          @click="successModal = false"
        >
          Continue
        </UiBtn>
      </div>
    </v-dialog>

  </div>
</template>

<script>
import {mapGetters } from 'vuex';
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: 'UserPassword',
  components: {
    IconCheckCircle: () => import('@/components/icons/IconCheckCircle'),

    UiBtn,
  },
  data() {
    return {
      loading: false,
      defPasswordData: null,
      passwordData: this.defaultPasswordData(),
      successModal: false,
    }
  },
  computed: {
    ...mapGetters([
      'getAuthId',
      'getUser',
      'getModal',
    ])
  },
  watch: {
    passwordData: {
      deep: true,
      handler(nv) {
        this.getModal('pageLeaveModal').dataSavedStatus = JSON.stringify(this.defPasswordData) === JSON.stringify(nv);
      }
    },
    defPasswordData: {
      deep: true,
      handler(nv) {
        this.getModal('pageLeaveModal').dataSavedStatus = JSON.stringify(this.defPasswordData) === JSON.stringify(nv);
      }
    },
  },
  created() {
    this.defPasswordData = JSON.parse(JSON.stringify(this.passwordData));
  },
  methods: {
    defaultPasswordData() {
       return {
         old_password: '',
         password: '',
         password_confirmation: '',
       }
    },
    submitHandler() {
      this.loading = true;

      this.$api.user.update(this.getAuthId, this.passwordData).then(() => {
        this.defPasswordData = JSON.parse(JSON.stringify(this.passwordData));

        this.successModal = true;
      })
        .catch(error => {
          if (error.response.status === this.$config.http.UNPROCESSABLE_ENTITY) {
            this.$refs.form.setErrors(error.response.data);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
}
</script>
