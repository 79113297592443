<template>
  <div style="font-size: 48px;" class="text-center mx-auto mt-16 py-16">
    Billing tab <br> is under construction
  </div>
<!--  <div>
    <h3 class="mb-7 text-body-lg font-weight-medium">Billing</h3>

    <div class="d-flex align-center">
      <div class="mr-12">
        <h6 class="text-body font-weight-thin mb-1">Your plan</h6>
        <p class="mb-0 text-body font-weight-semi-bold accent&#45;&#45;text">Professional</p>
      </div>

      <UiBtn
        width="130"
        color="accent"
        class="mr-5"
      >
        Upgrade Plan
      </UiBtn>


      <UiBtn
        outlined
        width="130"
        color="gray-60"
      >
        Cancel Plan
      </UiBtn>
    </div>

    <v-divider class="mt-12 mb-6"/>

    <h3 class="mb-7 text-body-lg font-weight-medium">Payment methods</h3>
    <div class="d-flex">
      <div class="d-flex overflow-x-auto">
        <div v-for="card in paymentCards" :key="card.id">
          <div class="payment-card d-flex align-center rounded mr-4 flex-shrink-0">
            <IconCreditCard width="40" class="mr-3"/>

            <div class="mr-7">
              <p class="mb-1 text-captions-1 font-weight-semi-bold gray-100&#45;&#45;text">{{ card.name }}</p>
              <div class="d-flex align-center text-captions-2 gray-60&#45;&#45;text">
                <img :src="require(`@/assets/images/payment/${card.type}.svg`)" alt="" width="15" class="mr-2">
                {{ card.number }} &nbsp;{{ card.expired_date }}
              </div>
            </div>

            <UiMenu
              left
              :nudge-left="12"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <UiBtn
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <IconDotsH width="18" class="gray-60&#45;&#45;text"/>
                </UiBtn>
              </template>

              <v-list nav dense color="gray-10">
                <v-list-item-group
                  v-model="card.size"
                >
                  <v-hover v-slot="{ hover }" v-for="(option, idx) in cardMenuOptions" :key="idx">
                    <v-list-item
                      :value="option.slug"
                    >
                      <template v-slot:default="{ active }">
                        <v-list-item-action class="ma-0">
                          <UiCheckbox
                            :input-value="active"
                            color="accent-100"
                            :hovered="hover"
                          />
                        </v-list-item-action>
                        <v-list-item-content class="py-1">
                          <v-list-item-title>{{ option.name }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </v-hover>
                </v-list-item-group>
              </v-list>
            </UiMenu>
          </div>
          <p v-if="card.default" class="mt-1 mb-0 text-captions-2 accent&#45;&#45;text">Default payment method</p>
        </div>
      </div>

      <UiBtn outlined color="gray-30" width="70" height="70" @click="addNewCardDialog = true;">
        <IconAddCreditCard width="40" class="accent&#45;&#45;text"/>
      </UiBtn>
    </div>

&lt;!&ndash;    <v-divider class="divider mt-12 mb-6"/>&ndash;&gt;

&lt;!&ndash;    <h2 class="mb-5 text-body-lg font-weight-medium">Invoice History</h2>&ndash;&gt;
&lt;!&ndash;    <v-data-table&ndash;&gt;
&lt;!&ndash;      :headers="headers"&ndash;&gt;
&lt;!&ndash;      :items="invoices"&ndash;&gt;
&lt;!&ndash;      hide-default-header&ndash;&gt;
&lt;!&ndash;      hide-default-footer&ndash;&gt;
&lt;!&ndash;      mobile-breakpoint="280"&ndash;&gt;
&lt;!&ndash;      class="mb-4"&ndash;&gt;
&lt;!&ndash;    >&ndash;&gt;
&lt;!&ndash;      <template #item="{item}">&ndash;&gt;
&lt;!&ndash;        <tr class="relative">&ndash;&gt;
&lt;!&ndash;          <td class="text-body">&ndash;&gt;
&lt;!&ndash;            <div class="d-flex align-center">&ndash;&gt;
&lt;!&ndash;              <IconPdf width="14" class="mr-3 accent&#45;&#45;text"/>&ndash;&gt;
&lt;!&ndash;              {{ item.date }}&ndash;&gt;
&lt;!&ndash;            </div>&ndash;&gt;
&lt;!&ndash;          </td>&ndash;&gt;
&lt;!&ndash;          <td class="text-body gray-60&#45;&#45;text">${{ item.price }}</td>&ndash;&gt;
&lt;!&ndash;          <td>&ndash;&gt;
&lt;!&ndash;            <UiBtn icon>&ndash;&gt;
&lt;!&ndash;              <IconDownload width="16" class="gray-60&#45;&#45;text"/>&ndash;&gt;
&lt;!&ndash;            </UiBtn>&ndash;&gt;
&lt;!&ndash;          </td>&ndash;&gt;
&lt;!&ndash;        </tr>&ndash;&gt;
&lt;!&ndash;        <tr class="spacer pointer-events-none"><td style="height: 10px;"/></tr>&ndash;&gt;
&lt;!&ndash;      </template>&ndash;&gt;
&lt;!&ndash;    </v-data-table>&ndash;&gt;

&lt;!&ndash;    <a role="button" class="text-body font-weight-semi-bold text-decoration-underline">&ndash;&gt;
&lt;!&ndash;      Show all invoices&ndash;&gt;
&lt;!&ndash;    </a>&ndash;&gt;

    &lt;!&ndash; In future we must move such dialogs into separate component, but for static it can be here :)   &ndash;&gt;
    <v-dialog
      v-model="addNewCardDialog"
      max-width="530"
      style="z-index: 999!important;"
    >
      <div class="gray-0 pt-9 pb-10 px-9">
        <h4 class="mb-9 text-title-2 gray-100&#45;&#45;text">Add a new Card</h4>

        <v-divider class="divider mt-2 mb-9"/>

        <ValidationObserver v-slot="{ handleSubmit }" tag="div" ref="form">
          <v-form @submit.prevent="handleSubmit(submitHandler)">
            <ValidationProvider class="mb-7" mode="eager" tag="div"
                                name="card holder name" vid="name" rules="max:64|required" v-slot="{ errors }">
              <div class="mb-2 text-body font-weight-light gray-100&#45;&#45;text">Card holder name</div>
              <v-text-field
                dense
                outlined
                hide-details
                value="Sebastian Giraldo"
                :error="!!errors.length"
                placeholder="Enter card holder name"
              />
              <div v-if="errors.length" class="mt-1 text-captions-1 error&#45;&#45;text">{{ errors[0] | capitalize }}</div>
            </ValidationProvider>

            <ValidationProvider class="mb-7" mode="eager" tag="div"
                                name="card number" vid="number" rules="required" v-slot="{ errors }">
              <div class="mb-2 text-body font-weight-light gray-100&#45;&#45;text">Card number</div>
              <v-text-field
                dense
                outlined
                hide-details
                value="1234 1233 1233 1233"
                :error="!!errors.length"
                placeholder="Enter card number"
              />
              <div v-if="errors.length" class="mt-1 text-captions-1 error&#45;&#45;text">{{ errors[0] | capitalize }}</div>
            </ValidationProvider>

            <div class="d-flex">
              <ValidationProvider class="mb-7 mr-6" mode="eager" tag="div"
                                  name="month" vid="month" rules="required" v-slot="{ errors }">
                <div class="mb-2 text-body font-weight-light gray-100&#45;&#45;text">Month</div>
                <v-text-field
                  dense
                  outlined
                  hide-details
                  value="01"
                  :error="!!errors.length"
                  placeholder="Month"
                />
                <div v-if="errors.length" class="mt-1 text-captions-1 error&#45;&#45;text">{{ errors[0] | capitalize }}</div>
              </ValidationProvider>

              <ValidationProvider class="mb-7 mr-6" mode="eager" tag="div"
                                  name="year" vid="year" rules="required" v-slot="{ errors }">
                <div class="mb-2 text-body font-weight-light gray-100&#45;&#45;text">Year</div>
                <v-text-field
                  dense
                  outlined
                  hide-details
                  value="12"
                  :error="!!errors.length"
                  placeholder="Year"
                />
                <div v-if="errors.length" class="mt-1 text-captions-1 error&#45;&#45;text">{{ errors[0] | capitalize }}</div>
              </ValidationProvider>

              <ValidationProvider class="mb-7" mode="eager" tag="div"
                                  name="cvv" vid="cvv" rules="required" v-slot="{ errors }">
                <div class="mb-2 text-body font-weight-light gray-100&#45;&#45;text">CVV</div>
                <v-text-field
                  dense
                  outlined
                  hide-details
                  value="078"
                  :error="!!errors.length"
                  placeholder="CVV"
                />
                <div v-if="errors.length" class="mt-1 text-captions-1 error&#45;&#45;text">{{ errors[0] | capitalize }}</div>
              </ValidationProvider>
            </div>

            <UiCheckbox
              color="accent-100"
              hide-details
              label="Use this as my default payment method"
              class="d-inline-flex mt-0 mb-10"
            />

            <div class="d-flex justify-end">
              <UiBtn
                outlined
                color="gray-60"
                width="136"
                @click="addNewCardDialog = false"
                class="mr-5"
              >
                Cancel
              </UiBtn>

              <UiBtn
                type="submit"
                color="accent"
                width="216"
              >
                Add Card
              </UiBtn>
            </div>
          </v-form>
        </ValidationObserver>
      </div>
    </v-dialog>

  </div>-->
</template>

<script>
// import UiBtn from "@/components/UI/UiBtn";
// import UiMenu from "@/components/UI/UiMenu";
// import UiCheckbox from "@/components/UI/UiCheckbox";

export default {
  name: 'UserBilling',
  components: {
    // IconCreditCard: () => import('@/components/icons/IconCreditCard'),
    // IconAddCreditCard: () => import('@/components/icons/IconAddCreditCard'),
    // IconDotsH: () => import('@/components/icons/IconDotsH'),
    // // IconPdf: () => import('@/components/icons/IconPdf'),
    // // IconDownload: () => import('@/components/icons/IconDownload'),
    //
    // UiBtn,
    // UiMenu,
    // UiCheckbox,
  },
  data() {
    return {
      addNewCardDialog: false,
      cardMenuOptions: [...this.$config.project.gridView.thumbnail].reverse(),
      paymentCards: [
        {id: 1, type: 'visa', name: 'Custom name space', number: '••••• 1245', expired_date: '09/24', default: true, size: null},
      ],
      headers: [
        {text: '', value: 'date', sortable: false},
        {text: '', value: 'price', sortable: false},
        {text: '', value: 'actions', sortable: false},
      ],
      invoices: [
        {id: 0, icon: '', date: 'April 29, 2021', price: '49.00', actions: ''},
        {id: 1, icon: '', date: 'April 29, 2021', price: '49.00', actions: ''},
        {id: 2, icon: '', date: 'April 29, 2021', price: '49.00', actions: ''},
        {id: 3, icon: '', date: 'April 29, 2021', price: '49.00', actions: ''},
        {id: 4, icon: '', date: 'April 29, 2021', price: '49.00', actions: ''},
        {id: 5, icon: '', date: 'April 29, 2021', price: '49.00', actions: ''},
      ],
    }
  },
  methods: {
    submitHandler() {
      this.addNewCardDialog = false;
    }
  }
}
</script>

<style scoped lang="scss">
.payment-card {
  height: 70px;
  padding: 0 12px 0 16px;
  border: 1px solid var(--v-gray-30-base);
}

::v-deep .v-data-table {
  tbody tr {
    &.spacer td {
      border: none!important;
    }
    &:not(.spacer) td {
      padding: 0 10px!important;
      height: 40px!important;
      border-top: 1px solid;
      border-color: var(--v-gray-10-base) !important;

      &:first-child {
        border-left: 1px solid;
      }

      &:nth-child(2) {
        width: 90px;
      }

      &:last-child {
        width: 52px;
        border-right: 1px solid;
      }
    }
  }
}
</style>
