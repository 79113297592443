<template>
  <div class="d-flex flex-column fill-height">
    <v-divider class="mt-14"/>

    <v-container>
      <div class="pt-7 text-body font-weight-semi-bold gray-60--text">
        {{ getUser.first_name || '' }} {{ getUser.last_name || '' }}
      </div>

      <h1 class="mb-6 text-title-1 gray-100--text">Account Preferences</h1>

      <v-tabs
        v-model="tab"
        slider-color="accent"
        slider-size="3"
        height="32"
        class="tabs mb-10"
      >
        <v-tab
          v-for="page in pages"
          :key="page.routeName"
          :to="{name: page.routeName}"
          class="tab relative text-captions-1 text-capitalize justify-start"
        >
          {{ page.label }}
        </v-tab>
      </v-tabs>

      <component :is="$route.name"/>

    </v-container>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import UserProfile from "./UserProfile";
import UserPassword from "./UserPassword";
import UserBilling from "./UserBilling";
import UserNotification from "./UserNotification";
import UserActivity from "./UserActivity";
import UserDownload from "./UserDownload";

export default {
  name: 'AccountPage',
  components: {
    UserProfile,
    UserPassword,
    UserBilling,
    UserNotification,
    UserActivity,
    UserDownload,
  },
  data() {
    return {
      tab: null,
      pages: [
        {label: 'Profile', routeName: 'UserProfile'},
        {label: 'Password', routeName: 'UserPassword'},
        {label: 'Billing', routeName: 'UserBilling'},
        {label: 'Notification', routeName: 'UserNotification'},
        {label: 'Activity', routeName: 'UserActivity'},
        {label: 'Download', routeName: 'UserDownload'},
      ]
    }
  },
  computed: {
    ...mapGetters([
      'getUser'
    ])
  },
}
</script>

<style scoped lang="scss">
::v-deep .v-slide-group__wrapper {
  overflow: initial;
  contain: initial;
}

::v-deep .tabs {
  .v-slide-group__prev,
  .v-slide-group__next {
    display: none!important;
  }
}

.tab {
  width: 153px;
  height: 30px;
  padding: 0 16px;
  color: var(--v-gray-30-base) !important;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding: 0 7px;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2px;
    height: 3px;
    background-color: var(--v-gray-30-base);
  }

  &.v-tab--active {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    color: var(--v-gray-60-base) !important;
  }
}
</style>
