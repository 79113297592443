<template>
  <div>
    <div class="d-flex justify-space-between mb-6 text-body-lg font-weight-medium align-center">Notifications
      <UiBtn color="primary" @click="generalNotificationsSettingDrawer = true">General Settings</UiBtn>
    </div>
    <div v-if="loading" class="d-flex justify-center pt-16">
      <v-progress-circular
        :size="100"
        color="accent"
        indeterminate
      />
    </div>
    <v-data-table
      v-else
      :headers="headers"
      :items="getProjectsNotifications"
      :items-per-page="-1"
      :mobile-breakpoint="280"
      hide-default-footer
      class="rounded-0"
    >
      <template #item="{item}">
        <tr class="relative">
          <td>
            <v-avatar rounded size="40" color="gray-90" style="border: 1px solid;">
              <v-img v-if="item.file" :src="item.file.url"/>
              <IconCube v-else style="width: 24px" class="gray-30--text"/>
            </v-avatar>
          </td>
          <td class="text-body font-weight-semi-bold gray-100--text">
            {{ item.title }}
          </td>
          <td>
            <div class="d-flex align-center">
              <div v-if="item.loading" class="d-flex align-center justify-center absolute inset-0 white"
                   style="z-index: 2;">
                <v-progress-circular :size="32" color="accent" indeterminate/>
              </div>
              <UiCheckbox
                v-model="item.notification_settings.email_notifications"
                label="Email"
                color="accent-100"
                class="mr-8"
                @change="saveNotifications(item)"/>
              <UiCheckbox
                v-model="item.notification_settings.app_notifications"
                label="In App"
                color="accent-100"
                class="mr-8"
                @change="saveNotifications(item)"/>
              <UiCheckbox
                v-model="item.notification_settings.webpush_notifications"
                label="Push"
                color="accent-100"
                class="mr-1"
                @change="saveNotifications(item)"/>
              <v-tooltip v-if="!getPushNotification || !isSupportPush" color="accent" top z-index="999">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" @click="subscribePush" class="d-flex cursor-pointer">
                    <IconInfoSquare width="14" class="accent--text"/>
                  </div>
                </template>
                <span v-if="!isSupportPush">Your browser not support push notification</span>
                <span v-else>Please enable browser notifications</span>
              </v-tooltip>
            </div>
          </td>
        </tr>
        <tr class="spacer pointer-events-none">
          <td style="height: 10px;"/>
        </tr>
      </template>
    </v-data-table>
    <GeneralNotificationsSetting v-model="generalNotificationsSettingDrawer"/>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import {checkPushInBrowser, pushSubscribe} from "@/utils/pushNotificationUtils";
import UiCheckbox from "@/components/UI/UiCheckbox";
import GeneralNotificationsSetting from "@/components/notifications/GeneralNotificationsSetting.vue";
import UiBtn from "@/components/UI/UiBtn.vue";

export default {
  name: 'UserNotification',
  components: {
    UiBtn,
    GeneralNotificationsSetting,
    IconCube: () => import('@/components/icons/IconCube'),
    IconInfoSquare: () => import('@/components/icons/IconInfoSquare'),
    UiCheckbox,
  },
  data() {
    return {
      loading: false,
      generalNotificationsSettingDrawer: false,
      headers: [
        {text: "Image", value: "image", sortable: false, width: '82px'},
        {text: "Name", value: "name", sortable: false},
        {text: "Notification options", value: "options", sortable: false, width: '320px'},
      ]
    }
  },
  computed: {
    ...mapGetters([
      'getProjectsNotifications',
      'getPushNotification',
    ]),
    isSupportPush() {
      return checkPushInBrowser();
    }
  },
  created() {
    this.loadNotifications();
  },
  methods: {
    async subscribePush() {
      try {
        await pushSubscribe()
        if (Notification.permission === 'granted') {
          await this.$store.dispatch('setPushNotification', true);
        }
      } catch (e) {
        console.warn('Notifications are not supported by this browser');
      }

    },
    async saveNotifications(project) {
      project.loading = true;
      try {
        await this.$api.notification.saveProjectsNotifications(project.id, project.notification_settings);
      } catch (error) {
        console.error(error);
      } finally {
        project.loading = false;
      }
    },
    loadNotifications() {
      this.loading = true;
      this.$api.notification.getProjectsNotifications()
        .then(res => {
          this.$store.dispatch('setProjectsNotifications', res.data);
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        })
    }
  },
}
</script>

<style scoped lang="scss">
::v-deep .v-data-table {
  border-top: 1px solid var(--v-gray-2-base);

  .v-data-table-header tr th {
    border-bottom: none !important;

    &:first-child {
      padding-left: 26px;
    }

    span {
      font-size: 11px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      font-weight: 400;
      color: var(--v-gray-45-base);
    }
  }

  tbody tr {
    &.spacer td {
      border: none !important;

      &::before {
        display: none;
      }
    }

    &:not(.spacer) td {
      height: 64px !important;
      border-top: 1px solid;
      border-color: var(--v-gray-2-base) !important;

      &:first-child {
        position: relative;
        padding-left: 26px;

        &::before {
          content: '';
          position: absolute;
          top: -1px;
          bottom: -1px;
          left: 0;
          width: 4px;
          background-color: var(--v-accent-base);
        }

        border-radius: 0 !important;
      }

      &:last-child {
        border-right: 1px solid;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
}

::v-deep .ui-checkbox .v-label {
  font-size: 14px;
  color: var(--v-gray-60-base);
}
</style>
