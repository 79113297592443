<template>
  <div>
    <h3 class="mb-8 text-body-lg font-weight-medium">Profile image</h3>

    <div v-if="loading" class="d-flex justify-center pt-16">
      <v-progress-circular
        :size="100"
        color="accent"
        indeterminate
      />
    </div>

    <ValidationObserver v-slot="{ handleSubmit }" tag="div" v-if="!loading" ref="form">
      <v-form @submit.prevent="handleSubmit(submitHandler)">
        <div class="d-flex align-center mb-8">
          <UiAvatar
            :src="userData.avatar ? userData.avatar : ''"
            :text="userData.first_name"
            text-class="text-header-1"
            size="88"
            class="mr-4"
          />

          <UIFilesUploader
            :files-formats="$config.filesystem.user.avatar.acceptedUploadFormats"
            :max-file-size="$config.filesystem.user.avatar.maxUploadFileSize"
            :disabled="formLoading"
            class="d-inline-block"
            style="cursor: pointer"
            @onChange="updateAvatarPreview"
          >
            <UiBtn
              outlined
              fab
              width="88"
              height="88"
              color="gray-60"
              :disabled="formLoading"
              style="pointer-events: none; border-radius: 50%;"
            >
              <IconPlusMaterial width="34" class="accent--text"/>
            </UiBtn>
          </UIFilesUploader>
        </div>

        <h3 class="mb-8 text-body-lg font-weight-medium">Name and personal information</h3>

        <div class="d-flex flex-wrap justify-space-between">
          <ValidationProvider class="input-wrap mb-7" mode="eager" tag="div"
                              name="your first name" vid="first_name" rules="max:64|required" v-slot="{ errors }">
            <div class="mb-2 text-body font-weight-light gray-100--text">First name</div>
            <v-text-field
              v-model="userData.first_name"
              dense
              outlined
              hide-details
              placeholder="Enter your name"
              :disabled="formLoading"
              :error="!!errors.length"
            />
            <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
          </ValidationProvider>

          <ValidationProvider class="input-wrap mb-7" mode="eager" tag="div"
                              name="last name" vid="your last_name" rules="max:64|required" v-slot="{ errors }">
            <div class="mb-2 text-body font-weight-light gray-100--text">Last name</div>
            <v-text-field
              v-model="userData.last_name"
              dense
              outlined
              hide-details
              placeholder="Enter your last name"
            />
            <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
          </ValidationProvider>

          <ValidationProvider class="input-wrap mb-7" mode="eager" tag="div"
                              name="title" vid="title" rules="max:64" v-slot="{ errors }">
            <div class="mb-2 text-body font-weight-light gray-100--text">Title</div>
            <v-text-field
              v-model="userData.title"
              dense
              outlined
              hide-details
              placeholder="Enter your last title"
              :disabled="formLoading"
              :error="!!errors.length"
            />
            <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
          </ValidationProvider>

          <ValidationProvider class="input-wrap mb-7" mode="eager" tag="div"
                              name="company" vid="company" rules="max:64" v-slot="{ errors }">
            <div class="mb-2 text-body font-weight-light gray-100--text">Company</div>
            <v-text-field
              v-model="userData.company"
              dense
              outlined
              hide-details
              placeholder="Enter your last company"
              :disabled="formLoading"
              :error="!!errors.length"
            />
            <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
          </ValidationProvider>

          <ValidationProvider class="input-wrap mb-7" mode="eager" tag="div"
                              name="your email" vid="email" rules="required|email" v-slot="{ errors }">
            <div class="mb-2 text-body font-weight-light gray-100--text">Email</div>
            <v-text-field
              v-model="userData.email"
              type="email"
              dense
              outlined
              readonly
              hide-details
              placeholder="Enter your email"
              :disabled="formLoading"
              :error="!!errors.length"
            />
            <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
          </ValidationProvider>

          <ValidationProvider class="input-wrap mb-7" mode="eager" tag="div"
                              name="phone" vid="phone" rules="phone" v-slot="{ errors }">
            <div class="mb-2 text-body font-weight-light gray-100--text">Phone</div>
            <phone-mask-input
              v-model="userData.phone"
              id="phone"
              ref="phone"
              placeholder="Enter your phone"
              class="input-phone"
              @input="onInput"
              :disabled="formLoading || phoneDisabled"
            />
            <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
          </ValidationProvider>
        </div>

        <div class="mb-2 text-body font-weight-light gray-100--text">Biography</div>
        <v-textarea
          v-model="userData.bio"
          dense
          outlined
          auto-grow
          rows="3"
          hide-details
          no-resize
          placeholder="Enter your biography"
          class="textarea"
          :disabled="formLoading"
        />

        <div class="text-right mt-16">
          <UiBtn
            type="submit"
            color="accent"
            width="216"
            :loading="formLoading"
          >
            Update Profile
          </UiBtn>
        </div>
      </v-form>
    </ValidationObserver>

    <v-dialog
      v-model="successModal"
      max-width="420"
      style="z-index: 999!important;"
    >
      <div class="relative gray-0 pt-12 pb-8 px-6 text-center">
        <h6 class="mb-6 text-title-2 gray-100--text">
          <IconCheckCircle width="24" class="mr-1 success--text" style="vertical-align: top"/>
          Profile Updated Successfully
        </h6>
        <p class="mb-5 text-body font-weight-thin">
          The changes will be reflected on any instance where your account has been used to upload files, comments, tasks or any other interaction.
        </p>
        <UiBtn
          color="accent"
          width="216"
          :loading="loading"
          @click="successModal = false"
        >
          Continue
        </UiBtn>
      </div>
    </v-dialog>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PhoneMaskInput from "vue-phone-mask-input";
import UIFilesUploader from "@/components/UI/UIFilesUploader";
import UiAvatar from "@/components/UI/UiAvatar";
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: 'UserProfile',
  components: {
    IconPlusMaterial: () => import('@/components/icons/IconPlusMaterial'),
    IconCheckCircle: () => import('@/components/icons/IconCheckCircle'),

    PhoneMaskInput,
    UIFilesUploader,
    UiAvatar,
    UiBtn,
  },
  data() {
    return {
      loading: true,
      formLoading: false,
      defUserData: {},
      userData: {
        avatar: '',
        avatar_id: null,
        first_name: '',
        last_name: '',
        title: '',
        company: '',
        email: '',
        phone: null,
        bio: '',
      },
      phoneDisabled: true,
      avatarFile: null,
      successModal: false,
    }
  },
  computed: {
    ...mapGetters([
      'getModal'
    ])
  },
  async created() {
    const res = await this.$api.user.get(this.$store.getters.getAuthId);
    const {avatar, first_name, last_name, title, company, email, phone, bio} = res.data;

    this.userData = {
      avatar: avatar?.url,
      avatar_id: avatar?.id,
      first_name, last_name, title, company, email, phone, bio,
    };

    for (const key of Object.keys(res.data)) {
      this.userData[key] = this.userData[key] === null ? '' : this.userData[key];
    }

    this.defUserData = JSON.parse(JSON.stringify(this.userData));
    this.loading = false;
    setTimeout(() => {
      this.phoneDisabled = false
    }, 100)
  },
  watch: {
    userData: {
      deep: true,
      handler(nv) {
        this.getModal('pageLeaveModal').dataSavedStatus = JSON.stringify(this.defUserData) === JSON.stringify(nv);
      }
    },
    defUserData: {
      deep: true,
      handler(nv) {
        this.getModal('pageLeaveModal').dataSavedStatus = JSON.stringify(this.defUserData) === JSON.stringify(nv);
      }
    }
  },
  methods: {
    onInput(e) {
      if(e.startsWith('+') && !this.$refs.phone.$el.querySelector("input").value.startsWith('+')) {
        this.$nextTick(() => {
          this.$refs.phone.$el.querySelector("input").value = `+${this.$refs.phone.$el.querySelector("input").value}`
        })
      }
    },
    submitHandler() {
      this.formLoading = true;

      if (this.userData.avatar !== this.defUserData.avatar) {
        if (this.userData.avatar_id) {
          this.$api.filesystem.delete(this.userData.avatar_id);
        }
        let formData = new FormData();
        formData.append("file[]", this.avatarFile);

        this.$api.filesystem.create(formData).then(res => {
          this.userData.avatar_id = res.data[0].id;
          this.updateUserData();
        });
      } else {
        this.updateUserData();
      }
    },
    updateUserData() {
      this.$api.user.update(this.$store.getters.getAuthId, this.userData)
        .then(res => {
          this.defUserData = JSON.parse(JSON.stringify(this.userData));
          this.formLoading = false;
          this.$store.dispatch('updateUser', res.data);
          this.successModal = true;
        })
        .catch(error => {
          if (error.response.status === this.$config.http.UNPROCESSABLE_ENTITY) {
            this.$refs.form.setErrors(error.response.data);
          }
          console.error(error);
        });
    },
    updateAvatarPreview(files) {
      const reader = new FileReader();
      this.avatarFile = files[0];

      reader.onload = () => {
        this.userData.avatar = reader.result;
      }

      if (this.avatarFile) {
        reader.readAsDataURL(files[0]);
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .input-phone {
  min-width: 100%;
  height: auto;
  input {
    width: 100%;
    height: 28px;
    padding: 0 16px;
    font-size: 12px;
    line-height: 16px;
    border-radius: 5px;
    border: 1px solid var(--v-gray-30-base);
    color: var(--v-gray-60-base);
    outline: none;
    &:focus {
      border-color: var(--v-accent-base);
    }
  }
}

.input-wrap,
::v-deep .input-phone {
  width: calc(100% / 3 - 10px);
}

.textarea textarea {
  max-height: 200px;
  overflow-y: auto;
}
</style>
